import React from "react";
import theme from "theme";
import { Theme, Text, Image, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"our-services"} />
		<Helmet>
			<title>
				Os nossos serviços | Os nossos serviços no Fit León Gym
			</title>
			<meta name={"description"} content={"O Fit León Gym dedica-se a oferecer uma gama completa de serviços que atendem a todos os aspectos do fitness e do bem-estar."} />
			<meta property={"og:title"} content={"Os nossos serviços | Os nossos serviços no Fit León Gym"} />
			<meta property={"og:description"} content={"O Fit León Gym dedica-se a oferecer uma gama completa de serviços que atendem a todos os aspectos do fitness e do bem-estar."} />
			<meta property={"og:image"} content={"https://braidolets.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://braidolets.com/img/9718001.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://braidolets.com/img/9718001.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://braidolets.com/img/9718001.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://braidolets.com/img/9718001.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://braidolets.com/img/9718001.png"} />
			<meta name={"msapplication-TileImage"} content={"https://braidolets.com/img/9718001.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 90px 0" quarkly-title="Product-6">
			<Override slot="SectionContent" lg-align-items="center" />
			<Text
				margin="0px 0px 20px 0px"
				font="normal 500 56px/1.2 --fontFamily-sans"
				text-align="center"
				lg-width="70%"
				md-width="100%"
				color="--darkL1"
			>
				Os nossos serviços
			</Text>
			<Text
				margin="0px 0px 40px 0px"
				text-align="center"
				font="normal 400 22px/1.5 --fontFamily-sansHelvetica"
				color="--darkL1"
				padding="0px 120px 0px 120px"
				lg-padding="0px 0 0px 0"
				lg-margin="0px 0px 50px 0px"
			>
				O Fit León Gym dedica-se a oferecer uma gama completa de serviços que atendem a todos os aspectos do fitness e do bem-estar. O nosso objetivo é garantir que tem acesso a todas as ferramentas e apoio necessários para o seu crescimento pessoal e desenvolvimento físico.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				md-margin="0px 0px 50px 0px"
				grid-gap="0 35px"
				lg-grid-gap="16px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="#d9e9fa"
					border-radius="4px"
				>
					<Image
						src="https://braidolets.com/img/s1.jpg"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						display="flex"
						flex-direction="column"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
							Programas de treino à medida
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
							- Treino pessoal: Sessões individuais com treinadores certificados, concebidas para atingir os seus objectivos específicos.
							<br />
							<br />
							- Aulas de grupo: Desde aulas de spinning de alta energia a sessões de ioga calmantes, envolva-se com a comunidade.
							<br />
							<br />
							- Workshops especializados: Workshops periódicos centrados em competências, técnicas ou tendências de fitness específicas.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#d9e9fa"
					border-radius="4px"
					display="flex"
					flex-direction="column"
				>
					<Image
						src="https://braidolets.com/img/s2.jpg"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						display="flex"
						flex-direction="column"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
							Instalações topo de gama
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
							- Cardio Zone: Equipado com as mais recentes passadeiras, elípticas e bicicletas para melhorar a saúde cardiovascular.
							<br />
							<br />
							- Força e condicionamento: Uma vasta gama de pesos livres, máquinas e equipamento de treino funcional.
							<br />
							<br />
							- Área de recuperação: Espaços dedicados para alongamentos pós-treino e rolamento de espuma para ajudar na recuperação e flexibilidade.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#d9e9fa"
					border-radius="4px"
					display="flex"
					flex-direction="column"
				>
					<Image
						src="https://braidolets.com/img/s3.jpg"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						display="flex"
						flex-direction="column"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
							Saúde e nutrição
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
							- Consulta de nutrição: Aconselhamento profissional para adaptar a sua dieta ao seu regime de treino para obter os melhores resultados.
							<br />
							<br />
							- Loja de suplementos: Uma seleção cuidada de suplementos para apoiar as suas necessidades de treino e recuperação.
							<br />
							<br />
							- Apoio da comunidade: Um lugar onde o encorajamento e a motivação são sempre abundantes.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section
			padding="230px 0 340px 0"
			background="linear-gradient(0deg,rgba(80, 57, 57, 0.7) 100%,rgba(0,0,0,0) 100%,#5a20ce 100%),#111828 url(https://braidolets.com/img/s4.jpg) 0% 0%/cover"
			color="#000000"
			sm-padding="80px 0 90px 0"
			md-padding="80px 0 80px 0"
			quarkly-title="Statistics-8"
		>
			<Box min-width="100px" min-height="100px" padding="0px 200px 0px 0px" lg-padding="0px 0 0px 0px">
				<Text margin="0px 0px 0px 0px" color="--light" font="normal 600 42px/1.2 --fontFamily-sans" sm-font="normal 600 36px/1.2 --fontFamily-sans">
					Ginásio Fit León - Onde cada desafio é uma oportunidade
				</Text>
				<Text margin="25px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="--light">
					Para uma compreensão mais aprofundada de como podemos personalizar os nossos serviços de acordo com as suas necessidades, contacte-nos. A nossa simpática equipa está pronta a fornecer todos os detalhes de que necessita para tomar uma decisão informada sobre juntar-se à nossa família de fitness.
				</Text>
			</Box>
		</Section>
		<Components.Contacts />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});